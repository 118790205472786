<section id="screenshots" class="screenshot-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="section-title">
            <h2>{{'Screenshots' | translate}}</h2>
        </div>

        <div class="screenshot-slider owl-carousel owl-theme">
            <div class="screenshot-item" *ngFor="let Image of screenshotImage;">
                <div class="image">
                    <img [src]="Image.img" alt="screenshots">
                </div>
            </div>
        </div>
    </div>
</section>