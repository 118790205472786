import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { AppLandingComponent } from './components/pages/app-landing/app-landing.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { AppBannerComponent } from './components/pages/app-landing/app-banner/app-banner.component';
import { AppFeaturesComponent } from './components/pages/app-landing/app-features/app-features.component';
import { AppAboutComponent } from './components/pages/app-landing/app-about/app-about.component';
import { AppScreenshotsComponent } from './components/pages/app-landing/app-screenshots/app-screenshots.component';
import { AppDownloadComponent } from './components/pages/app-landing/app-download/app-download.component';
import { AppHowItWorksComponent } from './components/pages/app-landing/app-how-it-works/app-how-it-works.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule } from '@angular/common/http';
import { PrivacyComponent } from './components/pages/app-landing/privacy/privacy.component';
import { ExchangePolicyComponent } from './components/pages/app-landing/exchange-policy/exchange-policy.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    AppLandingComponent,
    PreloaderComponent,
    AppBannerComponent,
    AppFeaturesComponent,
    AppAboutComponent,
    AppScreenshotsComponent,
    AppDownloadComponent,
    AppHowItWorksComponent,
    FooterComponent,
    PrivacyComponent,
    ExchangePolicyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}