import { Component } from '@angular/core';

@Component({
  selector: 'app-exchange-policy',
  templateUrl: './exchange-policy.component.html',
  styleUrls: ['./exchange-policy.component.scss']
})
export class ExchangePolicyComponent {
  exchangePolicyList = [];
  returnConditionList = [];

  constructor() {
    for(let i = 1; i <= 7; i++) {
      this.exchangePolicyList.push(
        'exchangePolicyPt' + i
      )
    };
    for(let i = 1; i <= 7; i++) {
      this.returnConditionList.push(
        'returnConditionPt' + i
      )
    }
  }
}
