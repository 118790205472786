<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      
      <button *ngIf="currentLang === 'en'" (click)="toggleLang('ar')" class="btn ph-0" mat-button>
        <span class="text-uppercase">
          العربية
        </span>
      </button>
      <button *ngIf="currentLang === 'ar'" (click)="toggleLang('en')" class="btn ph-0" mat-button>
        <span class="text-uppercase">
          English
        </span>
      </button>
      

        <a class="navbar-brand" href="/">
          <svg width="90" height="50" viewBox="0 0 165.379 110.343">
            <g id="Group_630" data-name="Group 630" transform="translate(-203 -306.809)">
              <path id="Path_213" data-name="Path 213" d="M183.828,168.675l.21-25.2,11.1,24.115,34.152,1.083,6.988-42.12-30.51-6.686,6.048,28.473-17.014-30.374L160.254,109.6l3.228,59.073Z" transform="translate(122.674 214.111)" fill="#006838"/>
              <path id="Path_214" data-name="Path 214" d="M246.706,127.832a2.855,2.855,0,0,0-3.215,2.439l-6.131,44.783h-75.5l-4.117-70.837a2.852,2.852,0,0,0-1.76-2.472l-16.34-6.74a2.853,2.853,0,0,0-2.175,5.274l14.683,6.056,4.17,71.736a2.852,2.852,0,0,0,2.847,2.687h80.683a2.853,2.853,0,0,0,2.827-2.466l6.469-47.248A2.853,2.853,0,0,0,246.706,127.832Z" transform="translate(119.208 212.021)" fill="#231f20"/>
              <path id="Path_215" data-name="Path 215" d="M174.312,191.464a8.986,8.986,0,0,0,10.52,0,6.835,6.835,0,0,0,.11-10.032,7.817,7.817,0,0,0-5.37-1.863,7.917,7.917,0,0,0-5.37,1.863,6.836,6.836,0,0,0,.111,10.032Z" transform="translate(124.341 223.987)" fill="#231f20"/>
              <path id="Path_218" data-name="Path 218" d="M215.188,193.165a8.293,8.293,0,0,0,5.261-1.7,6.837,6.837,0,0,0,.11-10.032,7.819,7.819,0,0,0-5.37-1.863,7.915,7.915,0,0,0-5.37,1.863,6.835,6.835,0,0,0,.11,10.032A8.293,8.293,0,0,0,215.188,193.165Z" transform="translate(129.368 223.987)" fill="#231f20"/>
              <path id="Path_221" data-name="Path 221" d="M150.556,133.557h-41.55a2.671,2.671,0,0,0,0,5.323h41.831Z" transform="translate(115.095 217.493)" fill="#dc4b3e"/>
              <path id="Path_222" data-name="Path 222" d="M114.483,157.658h36.723l-.293-5.579h-36.43a2.8,2.8,0,0,0,0,5.579Z" transform="translate(115.852 220.107)" fill="#dc4b3e"/>
              <path id="Path_223" data-name="Path 223" d="M123.189,143.372a2.8,2.8,0,0,0,0,5.578h26.266l-.294-5.578Z" transform="translate(117.081 218.878)" fill="#dc4b3e"/>
              <path id="Path_224" data-name="Path 224" d="M154.561,161.8H92.66a2.671,2.671,0,0,0,0,5.323h62.181Z" transform="translate(112.788 221.479)" fill="#dc4b3e"/>
              <path id="Path_225" data-name="Path 225" d="M113.594,118.648h36.723l-.294-5.579H113.594a2.8,2.8,0,0,0,0,5.579Z" transform="translate(115.726 214.601)" fill="#dc4b3e"/>
              <path id="Path_226" data-name="Path 226" d="M98.885,129h53.048l-.281-5.323H98.885a2.671,2.671,0,0,0,0,5.323Z" transform="translate(113.666 216.098)" fill="#dc4b3e"/>
            </g>
          </svg>
          <svg width="100" height="50" viewBox="0 0 100.153 42.88">
            <g id="Group_726" data-name="Group 726" transform="translate(-178.669 -311.532)">
              <path id="Path_240" data-name="Path 240" d="M206.922,210.113a.741.741,0,0,1-.224.543.831.831,0,0,1-.608.257h-27.1a7.777,7.777,0,0,0,1.44-4.639,6.993,6.993,0,0,0-2.271-5.407,6.878,6.878,0,0,0-4.83-1.79,6.619,6.619,0,0,0-5.822,2.942,7.258,7.258,0,0,0-1.248,4.254,7.51,7.51,0,0,0,1.44,4.639H151.923l-.327.456c-.647.915-1.282,1.85-1.885,2.806h20.517a7.756,7.756,0,0,0,3.007-.416h.16a7.9,7.9,0,0,0,3.007.416h30.936a3.336,3.336,0,0,0,2.623-1.215,3.648,3.648,0,0,0,.832-2.3v-11.2h-3.871Zm-31.272-.56a2.689,2.689,0,0,1-4.638,0,4.828,4.828,0,0,1-.88-2.991,4.927,4.927,0,0,1,.88-3.072,2.927,2.927,0,0,1,4.654,0,5,5,0,0,1,.863,3.072A4.832,4.832,0,0,1,175.65,209.554Z" transform="translate(68.029 127.86)" fill="#231f20"/>
              <path id="Path_241" data-name="Path 241" d="M117.643,210.739h-6.589a.641.641,0,0,1-.512-.224.715.715,0,0,1-.224-.576V198.422H108.4l-1.376,1.056a8.493,8.493,0,0,0-3.327-.7,6.779,6.779,0,0,0-5.022,2.079,8.711,8.711,0,0,0,.192,11.868,6.371,6.371,0,0,0,4.35,1.6,6.226,6.226,0,0,0,4.062-1.5A3.671,3.671,0,0,0,109.935,214h15.56c-.711-1.111-1.472-2.193-2.243-3.262Zm-11.227-.129a6.3,6.3,0,0,1-2.4.512,3.326,3.326,0,0,1-2.7-1.215,4.9,4.9,0,0,1-1.008-3.23,4.76,4.76,0,0,1,1.12-3.279,3.638,3.638,0,0,1,2.847-1.264,6.151,6.151,0,0,1,2.143.417Z" transform="translate(82.233 128.034)" fill="#231f20"/>
              <path id="Path_242" data-name="Path 242" d="M216.765,225.338l-.3-1.066-7.233,2.075.3,1.066,4.027-1.155Z" transform="translate(52.161 121.142)"/>
              <path id="Path_243" data-name="Path 243" d="M130.357,181.116l3.344-.961,3.651-1.049-.293-1.033-7.01,2.025Z" transform="translate(73.271 133.46)" fill="#231f20"/>
              <path id="Path_244" data-name="Path 244" d="M173.509,187.214a5.775,5.775,0,0,0,6.761,0,4.393,4.393,0,0,0,.07-6.447,5.024,5.024,0,0,0-3.451-1.2,5.088,5.088,0,0,0-3.451,1.2,4.393,4.393,0,0,0,.071,6.447Z" transform="translate(62.069 133.06)" fill="#231f20"/>
              <path id="Path_245" data-name="Path 245" d="M108.176,188.138a3.044,3.044,0,0,0,.049,4.467,4,4,0,0,0,4.684,0,3.044,3.044,0,0,0,.049-4.467,3.483,3.483,0,0,0-2.391-.829A3.526,3.526,0,0,0,108.176,188.138Z" transform="translate(79.356 130.997)" fill="#231f20"/>
              <path id="Path_246" data-name="Path 246" d="M98.63,188.138a3.043,3.043,0,0,0,.048,4.467,4,4,0,0,0,4.685,0,3.043,3.043,0,0,0,.048-4.467,3.483,3.483,0,0,0-2.391-.829A3.524,3.524,0,0,0,98.63,188.138Z" transform="translate(81.902 130.997)" fill="#231f20"/>
              <path id="Path_247" data-name="Path 247" d="M212.5,188.307a5.33,5.33,0,0,0,3.381-1.093,4.394,4.394,0,0,0,.07-6.447,5.025,5.025,0,0,0-3.451-1.2,5.087,5.087,0,0,0-3.451,1.2,4.393,4.393,0,0,0,.07,6.447A5.33,5.33,0,0,0,212.5,188.307Z" transform="translate(52.574 133.06)" fill="#231f20"/>
              <path id="Path_248" data-name="Path 248" d="M145.649,206.869c2.361-3.3,4.942-6.787,5.021-11v-.844a11.753,11.753,0,0,0-2.375-6.319,12.492,12.492,0,0,0-9.184-4.8h-1.426a12.773,12.773,0,0,0-8.073,3.569,11.6,11.6,0,0,0-3.211,5.833,12.263,12.263,0,0,0,1.315,8.23,36.337,36.337,0,0,0,3.031,4.728c.145.2.289.4.433.6.772,1.069,1.532,2.151,2.243,3.262a32.694,32.694,0,0,1,2.244,4.06,24.448,24.448,0,0,1,2.093,7.746,2.589,2.589,0,0,0,.652.571c.967-.365.741-1.5.929-2.307a26.215,26.215,0,0,1,4.095-10.07c.6-.956,1.238-1.891,1.885-2.806Zm-11.486-11.581a4.2,4.2,0,0,1,3.244-3.613,4.35,4.35,0,0,1,5,2.485,4.043,4.043,0,0,1-1.036,4.492,4.451,4.451,0,0,1-4.972.628A4.08,4.08,0,0,1,134.163,195.288Z" transform="translate(74.303 131.904)" fill="#dc4b3e"/>
              <path id="Path_249" data-name="Path 249" d="M139.289,202.058a4.451,4.451,0,0,0,4.972-.628,4.043,4.043,0,0,0,1.036-4.492,4.35,4.35,0,0,0-5-2.485,4.2,4.2,0,0,0-3.244,3.613A4.08,4.08,0,0,0,139.289,202.058Z" transform="translate(71.408 129.126)" fill="#721e1a"/>
            </g>
          </svg>      
            <!-- <img src="assets/img/logo.png" alt="logo"> -->
        </a>


        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item" *ngFor="let navItem of appLandingNavItem;"><a href="#{{navItem.id}}" class="nav-link">{{navItem.title | translate}}</a></li>
            </ul>
        </div>
    </div>
</nav>