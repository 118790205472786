import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-about',
    templateUrl: './app-about.component.html',
    styleUrls: ['./app-about.component.scss']
})
export class AppAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets/img/about-img.png'
        }
    ]
    aboutContent = [
        {
            title: 'appAboutContentTitle',
            paragraphList: [
                {
                    paragraph: 'appAboutContentDescription',
                },
            ],
            featuresList: [
                {
                    itemIcon: "las la-check",
                    item: "creativeDesign"
                },
                {
                    itemIcon: "las la-check",
                    item: "ongoingSupport"
                },
                {
                    itemIcon: "las la-check",
                    item: "modernDesign"
                },
            ]
        }
    ];

}
class Image {
    img : string;
}