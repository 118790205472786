<section id="features" class="app-features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{'appFeatures' | translate}}</h2>
            <p>{{'appFeaturesSubTitle' | translate}}</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let FeaturesContent of appFeatures;">
                <div class="single-app-features">
                    <div class="icon">
                        <img  [src]="FeaturesContent.path" [alt]="FeaturesContent.alt" width="40" height="40">
                        <!-- <i class="{{FeaturesContent.icon}}"></i> -->
                    </div>
                    <h3>{{FeaturesContent.title | translate}}</h3>
                    <p>{{FeaturesContent.paragraph | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</section>