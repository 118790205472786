import { ExchangePolicyComponent } from './components/pages/app-landing/exchange-policy/exchange-policy.component';
import { PrivacyComponent } from './components/pages/app-landing/privacy/privacy.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLandingComponent } from './components/pages/app-landing/app-landing.component';

const routes: Routes = [
    // { path: '', redirectTo: '', pathMatch: 'full' },
    {path: '', component: AppLandingComponent, title:'نفعة - كل ما يحتاج الشخص الحصول عليه من مأكولات وملبوسات واحتياجات متنوعة'},
    // {path: 'en', component: AppLandingComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'exchange-policy', component: ExchangePolicyComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}