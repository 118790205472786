<div id="home" class="app-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <h1> {{ 'MainTitle' | translate }} </h1>
                    <p> {{ 'bannerSubTitlePt1' | translate }} </p>
                    <p> {{ 'bannerSubTitlePt2' | translate }} </p>
                    <div class="banner-holder">                      
                        <a href="https://play.google.com/store/apps/details?id=com.nefaah.client_app" target="_blank"><img src="assets/img/store/1.png" alt="google-play-download"></a>
                        <a href="https://apps.apple.com/sa/app/nefaah/id6456452918?l=ar" target="_blank"><img src="assets/img/store/2.png" alt="app-store-download"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-image" *ngFor="let Image of bannerImage;">
                    <img [src]="Image.img" alt="header">
                </div>
            </div>
        </div>
    </div>
</div>