import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  EligibilityToReceiveServicesList = [];
  customerPledgesList = [];
  licenseScopeList = [];
  prohibitedProductsList = [];
  prohibitedProduct15DescList = [];
  compensationDescList = [];
  legalResponsibilityList = [];

  constructor() {
    for(let i = 1; i <= 3; i++) {
      this.EligibilityToReceiveServicesList.push(
        'EligibilityToReceiveServicesItem' + i
      )
    };
    for(let i = 1; i <= 12; i++) {
      this.customerPledgesList.push(
        'customerPledgesItem' + i
      )
    };
    for(let i = 1; i <= 5; i++) {
      this.licenseScopeList.push(
        'licenseScopeItem' + i
      )
    } 
    for(let i = 1; i <= 29; i++) {
      this.prohibitedProductsList.push(
        {
          title: 'prohibitedProduct' + i,
          desc: 'prohibitedProduct' + i + 'Desc'
        }
      )
    } 
    for(let i = 1; i <= 5; i++) {
      this.prohibitedProduct15DescList.push(
        'prohibitedProduct15DescPt' + i
      )
    }  
    for(let i = 1; i <= 3; i++) {
      this.compensationDescList.push(
        'compensationDescPt' + i
      )
    }
    for(let i = 1; i <= 5; i++) {
      this.legalResponsibilityList.push(
        'legalResponsibilityDescPt' + i
      )
    }         
  }
}
