import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-features',
    templateUrl: './app-features.component.html',
    styleUrls: ['./app-features.component.scss']
})
export class AppFeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
    appFeatures: FeaturesContent[] = [
        {
            path: 'assets/img/easy.png',
            alt:'EasyToUse',
            title: 'EasyToUse',
            paragraph: 'easyToUseDescription'
        },
        {
            path: 'assets/img/execution.png',
            alt:'comprehensiveness',
            title: 'Comprehensiveness',
            paragraph: 'comprehensivenessDescription'
        },
        {
            path: 'assets/img/best-price.png',
            alt:'best-price',
            title: 'Competition',
            paragraph: 'competitionDescription'
        },
    ]

}
class FeaturesContent {
    path : string;
    alt: string;
    title : string;
    paragraph : string;
}