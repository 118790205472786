<!-- Footer -->
<footer id="contact" class="footer">
  <!-- Section: Social media -->
  <!-- <section
    class="d-flex justify-content-center p-4 border-bottom"
  >

    <div>
      <a href="" class="me-4 link-secondary">
        <i class="fa fa-facebook-official"></i>
      </a>
      <a href="" class="me-4 link-secondary">
        <i class="fa fa-twitter"></i>
      </a>
      <a href="" class="me-4 link-secondary">
        <i class="fa fa-instagram"></i>
      </a>
    </div>
  </section> -->
  <!-- Section: Social media -->

  <!-- Section: Links  -->
  <section class="top-footer">
    <div class="container mt-5">
      <!-- Grid row -->
      <div class="row mt-3">
        <!-- Grid column -->
        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
          <!-- Content -->
          <h4 class="text-uppercase fw-bold mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="30" viewBox="0 0 166.153 74.704">
              <g id="nefaah" transform="translate(-56.766 -232.859)">
                <g id="Group_726" data-name="Group 726" transform="translate(56.766 232.859)">
                  <path id="Path_240" data-name="Path 240" d="M244.623,217.386a1.229,1.229,0,0,1-.371.9,1.378,1.378,0,0,1-1.009.426H198.29a12.9,12.9,0,0,0,2.388-7.7,11.6,11.6,0,0,0-3.768-8.969,11.411,11.411,0,0,0-8.013-2.97,10.981,10.981,0,0,0-9.658,4.881,12.041,12.041,0,0,0-2.071,7.058,12.459,12.459,0,0,0,2.39,7.7H153.38l-.543.757c-1.073,1.519-2.127,3.07-3.127,4.655h34.038a12.868,12.868,0,0,0,4.988-.69H189a13.115,13.115,0,0,0,4.988.69h51.322a5.534,5.534,0,0,0,4.352-2.016,6.052,6.052,0,0,0,1.38-3.822V199.713h-6.422Zm-51.879-.929a4.461,4.461,0,0,1-7.694,0,8.01,8.01,0,0,1-1.46-4.962,8.173,8.173,0,0,1,1.46-5.1,4.856,4.856,0,0,1,7.721,0,8.293,8.293,0,0,1,1.432,5.1A8.017,8.017,0,0,1,192.744,216.457Z" transform="translate(-84.892 -169.954)" fill="#fff"/>
                  <path id="Path_241" data-name="Path 241" d="M131.619,218.855H120.688a1.064,1.064,0,0,1-.849-.372,1.186,1.186,0,0,1-.371-.955V198.422h-3.184L114,200.174a14.09,14.09,0,0,0-5.519-1.168,11.245,11.245,0,0,0-8.332,3.449,14.451,14.451,0,0,0,.319,19.688,10.57,10.57,0,0,0,7.217,2.654,10.33,10.33,0,0,0,6.739-2.493,6.09,6.09,0,0,0,4.4,1.962h25.813c-1.18-1.843-2.442-3.638-3.722-5.412Zm-18.626-.214a10.446,10.446,0,0,1-3.98.849,5.518,5.518,0,0,1-4.483-2.016,8.123,8.123,0,0,1-1.673-5.359,7.9,7.9,0,0,1,1.858-5.44,6.036,6.036,0,0,1,4.723-2.1,10.205,10.205,0,0,1,3.555.691Z" transform="translate(-96.436 -170.097)" fill="#fff"/>
                  <path id="Path_242" data-name="Path 242" d="M221.731,226.041l-.5-1.769-12,3.442.5,1.769,6.681-1.916Z" transform="translate(-71.996 -164.496)" fill="#fff"/>
                  <path id="Path_297" data-name="Path 297" d="M221.731,226.041l-.5-1.769-12,3.442.5,1.769,6.681-1.916Z" transform="translate(-169.876 -224.272)" fill="#fff"/>
                  <path id="Path_243" data-name="Path 243" d="M130.559,183.122l5.548-1.595,6.058-1.741-.486-1.714-11.629,3.359Z" transform="translate(-89.153 -174.506)" fill="#231f20"/>
                  <path id="Path_244" data-name="Path 244" d="M174.461,192.252a9.581,9.581,0,0,0,11.217,0,7.287,7.287,0,0,0,.117-10.7,8.335,8.335,0,0,0-5.726-1.987,8.441,8.441,0,0,0-5.726,1.987,7.289,7.289,0,0,0,.118,10.7Z" transform="translate(-80.048 -174.183)" fill="#fff"/>
                  <path id="Path_245" data-name="Path 245" d="M108.8,188.685a5.05,5.05,0,0,0,.082,7.411,6.64,6.64,0,0,0,7.771,0,5.05,5.05,0,0,0,.082-7.411,5.778,5.778,0,0,0-3.966-1.376A5.849,5.849,0,0,0,108.8,188.685Z" transform="translate(-94.098 -172.505)" fill="#fff"/>
                  <path id="Path_246" data-name="Path 246" d="M99.257,188.685a5.049,5.049,0,0,0,.08,7.411,6.641,6.641,0,0,0,7.772,0,5.049,5.049,0,0,0,.08-7.411,5.778,5.778,0,0,0-3.966-1.376A5.846,5.846,0,0,0,99.257,188.685Z" transform="translate(-96.167 -172.505)" fill="#fff"/>
                  <path id="Path_247" data-name="Path 247" d="M215.685,194.065a8.842,8.842,0,0,0,5.609-1.813,7.29,7.29,0,0,0,.117-10.7,8.337,8.337,0,0,0-5.726-1.987,8.439,8.439,0,0,0-5.726,1.987,7.287,7.287,0,0,0,.117,10.7A8.842,8.842,0,0,0,215.685,194.065Z" transform="translate(-72.331 -174.183)" fill="#fff"/>
                  <path id="Path_248" data-name="Path 248" d="M158.48,222c3.917-5.467,8.2-11.26,8.329-18.251v-1.4a19.5,19.5,0,0,0-3.941-10.483,20.723,20.723,0,0,0-15.235-7.957h-2.365a21.191,21.191,0,0,0-13.393,5.922,19.245,19.245,0,0,0-5.327,9.677,20.344,20.344,0,0,0,2.182,13.654A60.286,60.286,0,0,0,133.758,221c.241.331.479.663.719,1,1.28,1.774,2.542,3.569,3.722,5.412a54.243,54.243,0,0,1,3.723,6.735A40.559,40.559,0,0,1,145.394,247a4.3,4.3,0,0,0,1.082.948c1.6-.606,1.23-2.486,1.541-3.828.889-6.054,3.556-11.571,6.793-16.706,1-1.585,2.054-3.137,3.127-4.655Zm-19.054-19.213a6.961,6.961,0,0,1,5.381-5.995,7.217,7.217,0,0,1,8.287,4.122,6.707,6.707,0,0,1-1.719,7.452,7.385,7.385,0,0,1-8.248,1.042A6.769,6.769,0,0,1,139.425,202.787Z" transform="translate(-89.991 -173.242)" fill="#dc4b3e"/>
                  <path id="Path_249" data-name="Path 249" d="M140.773,207.15a7.384,7.384,0,0,0,8.248-1.042,6.707,6.707,0,0,0,1.719-7.452,7.217,7.217,0,0,0-8.287-4.122,6.961,6.961,0,0,0-5.381,5.995A6.769,6.769,0,0,0,140.773,207.15Z" transform="translate(-87.638 -170.984)" fill="#721e1a"/>
                </g>
              </g>
            </svg>
            
          </h4>
          <p>
            {{ "MainTitle" | translate }}
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <!-- Links -->
          <h4 class="text-uppercase fw-bold mb-4">
            {{ "Content" | translate }}
          </h4>
          <p class="footer-link" *ngFor="let navItem of appLandingNavItem;">
            <a  (click)="scroll(navItem)" class="text-reset">{{navItem.title | translate}}</a>
          </p>
          <p>
            <a routerLink="privacy" class="text-reset">{{ "privacyAndPolicey" | translate }}</a>
          </p>
          <p>
            <a routerLink="exchange-policy" class="text-reset">{{ "exchangePolicy" | translate }}</a>
          </p>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 col-xl-5 mx-auto mb-md-0 mb-4">
          <!-- Links -->
          <h4 class="text-uppercase fw-bold mb-4">
            {{ "Contact" | translate }}
          </h4>
          <p>
            <i class="fa fa-map-marker"></i>
            {{ "Address" | translate }}
          </p>
          <p>
            <i class="fa fa-envelope"></i>
            {{ "Email" | translate }}
          </p>
          <p>
            <i class="fa fa-twitter"></i>
            {{ "Twitter" | translate }}
          </p>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </div>
  </section>
  <!-- Section: Links  -->

  <!-- Copyright -->
  <div class="copy-right">
    <div class="container">
      <p>
        <i class="las la-copyright"></i> {{ 'CopyRights' | translate }}
      </p>
    </div>
  </div>
  <!-- Copyright -->
</footer>
<!-- Footer -->
