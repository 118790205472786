import { SeoService } from './../../../services/seo.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocalizationService } from 'src/app/services/localization.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})

export class NavbarComponent implements OnInit {

    location: any;
    routerSubscription: any;
    currentLang: string;
    subscribtion: Subscription;

    constructor(
        private router: Router,
        private localization: LocalizationService,
        public translate: TranslateService,
        private seoService: SeoService,
        private titleService: Title
        ) {
    }

    ngOnInit(){
        this.observeCurrentLang();
        this.recallJsFuntions();
    }

    observeCurrentLang(): void {
        this.subscribtion = this.localization.getCurrentLanguage().subscribe(lang => {
          this.currentLang = lang;
        });
      }


    toggleLang(lang): void {        
        if (lang !== this.currentLang) {
        window.location.reload();
          this.localization.setLanguage(lang);
          this.localization.toggleDirection(lang);
        //   this.seoService.refreshSeo(lang);
          this.translate.get("pageTitle").subscribe(name=>{
            this.titleService.setTitle(name);            
          });
        }
      }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
        });
    }
    
    appLandingNavItem = [
        {
            id: 'home',
            title: 'Home',
        },
        {
            id: 'features',
            title: 'Features',
        },
        {
            id: 'howItWorks',
            title: 'HowItWorks',
        },
        {
            id: 'screenshots',
            title: 'Screenshots',
        },
        {
            id: 'contact',
            title: 'Contact',
        }
        
    ];
}