<section class="app-download ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-download-image text-center" *ngFor="let Image of appDownloadImage;">
                    <img [src]="Image.img" alt="download-banner">
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="app-download-content" *ngFor="let Content of appDownloadContent;">
                    <h3>{{'DownloadAppTitle' | translate}}</h3>
                    <p>{{'DownloadDescription' | translate}}</p>
                    <div class="app-holder">
                        <a href="#"><img [src]="Content.button1" alt="app-store-download"></a>
                        <a href="#"><img [src]="Content.button2" alt="google-play-download"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>