<div class="privacy-container">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title-container">
                    <h1> {{ 'privacyPolicy' | translate }} </h1>
                </div>

                <div class="accordion mt-4" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                                aria-controls="panelsStayOpen-collapseOne">
                                {{ 'privacyPolicyForClients' | translate }}
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne">
                            <div class="accordion-body">
                                <strong>{{'privacyPolicyForClientsIntro' | translate}}</strong>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTwo">
                                {{ 'EligibilityToReceiveServices' | translate }}
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwo">
                            <div class="accordion-body">
                                <ol class="list">
                                    <li *ngFor="let item of EligibilityToReceiveServicesList"> {{ item | translate }}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseThree">
                                {{ 'customerPledges' | translate }} </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingThree">
                            <div class="accordion-body">
                                <ol class="list">
                                    <li *ngFor="let item of customerPledgesList"> {{ item | translate }} </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFour">
                                {{ 'licenseScope' | translate }}
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingFour">
                            <div class="accordion-body">
                                <p> {{'licenseScopeIntro' | translate}} {{ 'SoYouShould' | translate }} </p>
                                <ol class="list">
                                    <li *ngFor="let item of licenseScopeList"> {{ item | translate }} </li>
                                </ol>
                                <p> {{ 'licenseScopeLastPart' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFive">
                                {{ 'prohibitedProducts' | translate }} </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingFive">
                            <div class="accordion-body">
                                <ul class="list">
                                    <li *ngFor="let item of prohibitedProductsList; let i = index">
                                       <strong>{{ item.title | translate }} :</strong>  
                                        {{ item.desc | translate }}
                                        <ul *ngIf="i === 14">
                                            <li *ngFor="let descriptionPoint of prohibitedProduct15DescList">
                                                {{ descriptionPoint | translate}}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingSix">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseSix">
                                {{ 'intellectualPropertyRights' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingSix">
                            <div class="accordion-body">
                                <p> {{ 'intellectualPropertyRightsDesc' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseSeven">
                                {{ 'payment' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingSeven">
                            <div class="accordion-body">
                                <p> {{ 'paymentDescPt1' | translate }} </p>
                                <p> {{'paymentDescPt2' | translate}} </p>
                                <p> {{ 'paymentDescPt3' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingEight">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseEight">
                                {{ 'pricingMethod' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingEight">
                            <div class="accordion-body">
                                <p> {{ 'pricingMethodDescPt1' | translate }} </p>
                                <p> {{'pricingMethodDescPt2' | translate}} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingNine">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseNine" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseNine">
                                {{ 'Compensation' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseNine" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingNine">
                            <div class="accordion-body">
                                <p> {{ 'compensationDesc' | translate }} </p>
                                <ul>
                                    <li *ngFor="let item of compensationDescList"> {{ item | translate }} </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingTen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTen" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTen">
                                {{ 'legalResponsibility' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTen" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTen">
                            <div class="accordion-body">
                                <p *ngFor="let item of legalResponsibilityList"> {{ item | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingEleven">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseEleven" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseEleven">
                                {{ 'modifyServices' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseEleven" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingEleven">
                            <div class="accordion-body">
                                <p> {{ 'modifyServicesDescPt1' | translate }} </p>
                                <ul>
                                    <li> {{ 'modifyServicesDescPt2' | translate }} </li>
                                </ul>
                                <p> {{ 'modifyServicesDescPt3' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingTwelve">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseTwelve" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseTwelve">
                                {{ 'limitationOfLiability' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwelve" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingTwelve">
                            <div class="accordion-body">
                                <p> {{ 'limitationOfLiabilityDescPt1' | translate }} </p>
                                <p> {{ 'limitationOfLiabilityDescPt2' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingThirteen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseThirteen" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseThirteen">
                                {{ 'safety' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThirteen" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingThirteen">
                            <div class="accordion-body">
                                <p> {{ 'safetyDesc' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingFourteen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseFourteen" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFourteen">
                                {{ 'dealingsWithOthers' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFourteen" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingFourteen">
                            <div class="accordion-body">
                                <p> {{ 'dealingsWithOthersDescPt1' | translate }} </p>
                                <p> {{ 'dealingsWithOthersDescPt2' | translate }} </p>
                                <p> {{ 'dealingsWithOthersDescPt3' | translate }} </p>
                                <p> {{ 'dealingsWithOthersDescPt4' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingFifteen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseFifteen" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseFifteen">
                                {{ 'contractTermAndTermination' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseFifteen" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingFifteen">
                            <div class="accordion-body">
                                <p> {{ 'contractTermAndTerminationDescPt1' | translate }} </p>
                                <p> {{ 'contractTermAndTerminationDescPt2' | translate }} </p>
                                <ul>
                                    <li> {{'contractTermAndTerminationPoint1' | translate}} </li>
                                    <li> {{'contractTermAndTerminationPoint2' | translate}} </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingSixteen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseSixteen" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseSixteen">
                                {{ 'Invalidation' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseSixteen" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingSixteen">
                            <div class="accordion-body">
                                <p> {{ 'InvalidationDescPt1' | translate }} </p>
                                <p> {{ 'InvalidationDescPt2' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingSivnteen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseSivnteen" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseSivnteen">
                                {{ 'modifyServiceUserTerms' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseSivnteen" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingSivnteen">
                            <div class="accordion-body">
                                <p> {{ 'modifyServiceUserTermsDesc' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingEighteen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseEighteen" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseEighteen">
                                {{ 'notification' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseEighteen" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingEighteen">
                            <div class="accordion-body">
                                <p> {{ 'notificationDesc' | translate }} </p>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="panelsStayOpen-headingNinteen">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#panelsStayOpen-collapseNinteen" aria-expanded="false"
                                aria-controls="panelsStayOpen-collapseNinteen">
                                {{ 'applicableLaw' | translate }} 
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseNinteen" class="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingNinteen">
                            <div class="accordion-body">
                                <p> {{ 'applicableLawDesc' | translate }} </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>