<app-app-banner></app-app-banner>

<app-app-features></app-app-features>

<app-app-about></app-app-about>

<app-app-how-it-works></app-app-how-it-works>

<app-app-download></app-app-download>

<app-app-screenshots></app-app-screenshots>
