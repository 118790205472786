import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    appLandingNavItem = [
        {
            id: 'home',
            title: 'Home',
        },
        {
            id: 'features',
            title: 'Features',
        },
        {
            id: 'howItWorks',
            title: 'HowItWorks',
        },
        {
            id: 'screenshots',
            title: 'Screenshots',
        },
        {
            id: 'contact',
            title: 'Contact',
        }
        
    ];

    scroll(element: any) {        
        document.getElementById(`${element.id}`).scrollIntoView();
      }
}