import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-app-screenshots',
    templateUrl: './app-screenshots.component.html',
    styleUrls: ['./app-screenshots.component.scss']
})
export class AppScreenshotsComponent implements OnInit {
    browserLang: string;
    screenshotImage: Image[];

    constructor(private translate: TranslateService,) {
 

     }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.browserLang = event.lang;
            this.browserLang === 'ar' ? this.screenshotImage = [
                {
                    img: 'assets/img/screenshot/screen1-ar.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen2-ar.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen3-ar.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen4-ar.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen5-ar.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen6-ar.jpeg'
                },
            ] : this.screenshotImage = [
                {
                    img: 'assets/img/screenshot/screen1-en.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen2-en.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen3-en.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen4-en.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen5-en.jpeg'
                },
                {
                    img: 'assets/img/screenshot/screen6-en.jpeg'
                },
            ] 
            
        });
    }
}
class Image {
    img : string;
}