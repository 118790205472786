import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateKeywords(keywords: string) {
    this.meta.addTag({ name: 'keywords', content: keywords })
  }

  refreshSeo(lang:string) {
    let seoData = localStorage.getItem('seo_data');
    let seo = JSON.parse(seoData);
    this.updateTitle(seo.meta_title[lang]);
    this.updateDescription(seo.meta_describtion[lang]);
    this.updateKeywords(seo.keywords[lang]);
  }
}
