<div class="exhange-policy__container">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title-container">
                    <h1> {{ 'exchangePolicy' | translate }} </h1>
                </div>
                <div>
                    <ol class="exhange-policy__container-list">
                        <li class="fw-bold" *ngFor="let item of exchangePolicyList; let i = index"> {{ item | translate }}
                            <ul *ngIf="i === 6">
                                <li class="fw-normal"> {{ 'returnPolicyPt1' | translate}} </li>
                                <li class="fw-normal"> {{ 'returnPolicyPt2' | translate}} </li>
                            </ul>          
                        </li>
                    </ol>
                    <h4> {{ 'returnCondition' | translate }} </h4>
                    <ol class="exhange-policy__container-list">
                        <li class="fw-bold" *ngFor="let item of returnConditionList"> {{ item | translate }} </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>