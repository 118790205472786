<!-- <section id="howItWorks" class="pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>{{'HowItWorks' | translate}}</h2>
            <p>{{'HowItWorks' | translate}}</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="wrapper">
                    <ol class="c-stepper">
                      <li class="c-stepper__item">
                        <div class="numberCircle">1</div>
                        <div class="c-stepper__content">
                          <h3 class="c-stepper__title">{{'step1' | translate}}</h3>
                          <p class="c-stepper__desc"> {{ 'step1Description' | translate }} </p>
                        </div>
                      </li>
                      <li class="c-stepper__item">
                        <div class="numberCircle">2</div>

                        <div class="c-stepper__content">
                          <h3 class="c-stepper__title"> {{ 'step2' | translate }} </h3>
                          <p class="c-stepper__desc"> {{ 'step2Description' | translate}} </p>
                          <p class="c-stepper__desc">Some desc text</p>
                        </div>
                      </li>
                      <li class="c-stepper__item">
                        <div class="numberCircle">3</div>

                        <div class="c-stepper__content">
                          <h3 class="c-stepper__title">{{ 'step3' | translate }}</h3>
                          <p class="c-stepper__desc">{{ 'step3Description' | translate}}</p>
                          <p class="c-stepper__desc">Some desc text</p>
                        </div>
                      </li>
                      <li class="c-stepper__item">
                        <div class="numberCircle">4</div>

                        <div class="c-stepper__content">
                          <h3 class="c-stepper__title">{{ 'step4' | translate }}</h3>
                          <p class="c-stepper__desc"> {{ 'step4Description' | translate }} </p>
                          <p class="c-stepper__desc">Some desc text</p>
                        </div>
                      </li>
                    </ol>
                  </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">

          </div>
        </div>
    </div>
</section> -->



<section id="howItWorks" class="faq-area pt-100 pb-70">
  <div class="container">
      <div class="section-title">
          <h2>{{'HowItWorks' | translate}}</h2>
      </div>

      <div class="main-section row">
          <div class="col-md-12">
              <h3 class="mb-5">** {{'askARestaurant' | translate}} </h3>
          </div>
          <div class="col-md-3">
              <div class="step">
                  <div class="icon-container">
                      <i class="fa fa-map-marker"></i>
                  </div>
                  <h5>{{'detectLocation' | translate}}</h5>
              </div>
          </div>
          <div class="col-md-3">
              <div class="step">
                  <div class="icon-container">
                      <i class="la la-shopping-basket"></i>
                  </div>
                  <h5>{{'fillBasket' | translate}}</h5>
              </div>
          </div>
          <div class="col-md-3">
              <div class="step">
                  <div class="icon-container">
                      <i class="la la-money-bill-wave"></i>
                  </div>
                  <h5>{{'pay' | translate}}</h5>
              </div>
          </div>
          <div class="col-md-3">
              <div class="step">
                  <div class="icon-container">
                      <i class="la la-handshake"></i>
                  </div>
                  <h5>{{'receive' | translate}}</h5>
              </div>
          </div>
      </div>


      <div class="row mt-5">
          <div class="col-md-12">
              <h3 class="mb-5">** {{'orderAnything' | translate}} </h3>
          </div>
          <div class="col-md-3">
              <div class="step">
                  <div class="icon-container">
                      <i class="fa fa-map-marker"></i>
                  </div>
                  <h5>{{'pickupLocation' | translate}}</h5>
              </div>
          </div>
          <div class="col-md-3">
              <div class="step">
                  <div class="icon-container">
                      <i class="la la-envelope-open-text"></i>
                  </div>
                  <h5>{{'parcelInfo' | translate}}</h5>
              </div>
          </div>
          <div class="col-md-3">
              <div class="step">
                  <div class="icon-container">
                      <i class="la la-money-bill-wave"></i>
                  </div>
                  <h5>{{'pay' | translate}}</h5>
              </div>
          </div>
          <div class="col-md-3">
              <div class="step">
                  <div class="icon-container">
                      <i class="la la-handshake"></i>
                  </div>
                  <h5>{{'receive' | translate}}</h5>
              </div>
          </div>
      </div>
  </div>
</section>